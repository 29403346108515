<template>
  <div v-if="isLoading" class="spin bg-transparent">
    <a-spin />
  </div>
  <div v-else class="grid grid-cols-10 m-16px gap-16px location-tab">
    <div class="2xl:col-span-7 col-span-6 bg-ems-gray800 p-[20px]">
      <div class="flex justify-between gap-[16px] items-center mb-[17px]">
        <div class="text-ems-gray200 uppercase text-base font-semibold">
          {{ t('object_tracing.location') }}
        </div>
        <div class="flex gap-[10px]">
          <a-tooltip placement="top" overlayClassName="tooltip-name-icon">
            <template #title>
              <span class="text-sm text-ems-white">
                {{ t('object_tracing.common-io') }}</span
              >
            </template>
            <img
              :src="
                activeMap === MAP_TYPE.COMMON_IO
                  ? require('@/static/img/icon/common-io-active.png')
                  : require('@/static/img/icon/common-io.png')
              "
              width="28"
              height="28"
              class="w-[28px] h-[28px] flex-shrink-0 object-cover cursor-pointer"
              @click="changeMap(MAP_TYPE.COMMON_IO)"
            />
          </a-tooltip>
          <a-tooltip placement="top" overlayClassName="tooltip-name-icon">
            <template #title>
              <span class="text-sm text-ems-white">
                {{ t('object_tracing.travel-route') }}</span
              >
            </template>
            <img
              :src="
                activeMap === MAP_TYPE.TRAVEL_ROUTE
                  ? require('@/static/img/icon/travel-active.png')
                  : require('@/static/img/icon/travel.png')
              "
              width="28"
              height="28"
              class="w-[28px] h-[28px] flex-shrink-0 object-cover cursor-pointer"
              @click="changeMap(MAP_TYPE.TRAVEL_ROUTE)"
            />
          </a-tooltip>
          <a-tooltip placement="top" overlayClassName="tooltip-name-icon">
            <template #title>
              <span class="text-sm text-ems-white">
                {{ t('object_tracing.tracking-cam') }}</span
              >
            </template>
            <img
              :src="
                activeMap === MAP_TYPE.CAMERA_LOCATION
                  ? require('@/static/img/icon/cam-active.png')
                  : require('@/static/img/icon/cam.png')
              "
              width="28"
              height="28"
              class="w-[28px] h-[28px] flex-shrink-0 object-cover cursor-pointer"
              @click="changeMap(MAP_TYPE.CAMERA_LOCATION)"
            />
          </a-tooltip>
          <a-tooltip placement="top" overlayClassName="tooltip-name-icon">
            <template #title>
              <span class="text-sm text-ems-white">
                {{ t('object_tracing.heatmap') }}</span
              >
            </template>
            <img
              :src="
                activeMap === MAP_TYPE.HEATMAP
                  ? require('@/static/img/icon/heatmap-active.png')
                  : require('@/static/img/icon/heatmap.png')
              "
              width="28"
              height="28"
              class="w-[28px] h-[28px] flex-shrink-0 object-cover cursor-pointer"
              @click="changeMap(MAP_TYPE.HEATMAP)"
            />
          </a-tooltip>
        </div>
      </div>
      <div v-if="isLoadingMap" class="spin bg-transparent">
        <a-spin />
      </div>
      <GoongLineMap
        v-else-if="
          showCommonMap && !isLoadingMap && activeMap === MAP_TYPE.COMMON_IO
        "
        class="!h-[calc(100vh-300px)]"
        ref="commonMap"
        :markers="markersCommon"
        :center="center"
        @click-marker="onClickMarkerCommon"
      />
      <div
        v-else-if="activeMap === MAP_TYPE.TRAVEL_ROUTE && !isLoadingMap"
        class="relative"
      >
        <GoongLineMap
          ref="travelMap"
          :markers="markersTravel"
          :center="center"
          class="!h-[calc(100vh-300px)]"
          :flightPath="flightPathTravel"
          :markerUser="markersTrinhSat"
          @click-marker="onClickMarkerTravel"
        />
        <div class="absolute top-[66px] left-[8px]">
          <a-popover placement="right" overlay-class-name="popup-groups">
            <template #content>
              <div class="text-ems-white font-semi-bold">
                {{ t('object_tracing.position-user') }}
              </div>
            </template>
            <img
              :src="
                showTrinhSat
                  ? require('@/static/img/icon/trinhsat-active.png')
                  : require('@/static/img/icon/trinhsat.png')
              "
              alt="trinhsat"
              width="43"
              height="43"
              class="cursor-pointer !mb-5"
              @click="onClickShowTS"
            />
          </a-popover>
          <a-popover placement="right" overlay-class-name="popup-groups">
            <template #content>
              <div class="text-ems-white font-semi-bold">
                {{ t('object_tracing.last-location') }}
              </div>
            </template>
            <img
              :src="require('@/static/img/icon/vtri-cuoi.png')"
              alt="lastlocation"
              width="43"
              height="43"
              class="cursor-pointer"
              @click="moveLastLocation"
            />
          </a-popover>
        </div>
      </div>
      <GoongLineMap
        v-else-if="activeMap === MAP_TYPE.CAMERA_LOCATION && !isLoadingMap"
        ref="cameraMap"
        class="!h-[calc(100vh-300px)]"
        :markers="markersCam"
        :center="center"
        @click-marker="onClickMarkerCam"
      />
      <Heatmap
        v-else-if="activeMap === MAP_TYPE.HEATMAP && !isLoadingMap"
        ref="heatmap"
        class="!h-[calc(100vh-300px)]"
        :markers="markersHeatmap"
        :center="center"
      />
    </div>
    <div class="2xl:col-span-3 col-span-4 bg-ems-gray800 p-[20px]">
      <div class="text-lg font-semibold text-ems-gray200 mb-[22px]">
        {{ t(`object_tracing.title${activeMap}`) }}
      </div>
      <div v-if="isLoadingMap" class="spin bg-transparent">
        <a-spin />
      </div>
      <CommonIO
        v-else-if="activeMap === MAP_TYPE.COMMON_IO && !isLoadingMap"
        ref="commonIO"
        @selectIO="selectIO"
        @on-search-data="onSearch"
        :camInfo="camInfo"
        :isLoading="isLoadingList"
        :groupCamera="groupCamera"
      />
      <TravelRoute
        v-else-if="activeMap === MAP_TYPE.TRAVEL_ROUTE && !isLoadingMap"
        ref="travelRoute"
        @selectItem="selectLocation"
        @on-search-data="onSearchLocation"
        :isLoading="isLoadingList"
      />
      <CameraLocation
        v-else-if="activeMap === MAP_TYPE.CAMERA_LOCATION && !isLoadingMap"
        ref="cameraLocation"
        @selectCamera="selectCamera"
        @on-search-data="onSearchCamera"
        :camInfo="camInfo"
        :isLoading="isLoadingList"
        :groupCamera="groupCamera"
      />
      <div v-else-if="activeMap === MAP_TYPE.HEATMAP && !isLoadingMap">
        <cds-from-to-date
          v-model:dateFrom="formStateHeatmap.dateFrom"
          v-model:dateTo="formStateHeatmap.dateTo"
          :is-show-label="false"
          :allow-clear="true"
          class="from-to-date mb-[10px]"
        />
      </div>
    </div>
  </div>
  <div
    v-if="showOnlineCam"
    class="absolute bottom-0 right-0 w-[750px] h-[450px] border-1px border-solid border-ems-white"
  >
    <div
      class="flex justify-between items-center py-[12px] px-[15px] bg-ems-main2"
    >
      <div class="flex gap-[18px]">
        <span class="text-[12px] text-ems-white font-bold flex items-center">
          <img
            src="@/static/img/icon/camera.png"
            width="15"
            height="15"
            class="w-[15px] h-[15px] mr-1"
          />
          {{ onlineCam.camera.name }}
        </span>
        <span class="text-12px text-ems-white mt-2px">{{
          formatDate(dayjs(), 'HH:mm A DD/MM/YYYY')
        }}</span>
      </div>
      <img
        src="@/static/img/icon/close.png"
        width="15"
        height="15"
        class="w-[15px] h-[15px] cursor-pointer flex-shrink-0 object-cover"
        @click="showOnlineCam = false"
      />
    </div>
    <img
      :src="onlineCam.imageUrl"
      class="w-full h-[calc(100%-39px)] object-cover"
    />
  </div>
</template>
<script setup>
import { computed, onMounted, ref, reactive, watch } from 'vue';
import Heatmap from '@/components/goong-heatmap/index.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import CommonIO from './CommonIO.vue';
import TravelRoute from './TravelRoute.vue';
import CameraLocation from './CameraLocation.vue';
import { formatDate } from '@/util/common-utils';
import {
  MARKER_TYPE,
  MAP_TYPE,
  EVENT_STATUS_VALUE,
} from '@/util/common-constant.js';
import dayjs from 'dayjs';
import GoongLineMap from '@/components/goong-line-map/index.vue';

var relativeTime = require('dayjs/plugin/relativeTime');
const { t } = useI18n();
const { state, dispatch } = useStore();
const route = useRoute();
const isLoading = ref(true);
const isLoadingMap = ref(false);
const activeMap = ref(MAP_TYPE.COMMON_IO);
const markersCommon = ref([]);
const markersTrinhSat = ref([]);
const center = ref({ lat: 21.051448, lng: 105.8002 });
const commonMap = ref();
const commonIO = ref();
const travelRoute = ref();
const groupCamera = ref([]);
const getCamera = () => {
  let detail = state.tracingObject.tracingObjectDetail;
  let data = {};
  if (detail && detail.cameraInfo) {
    for (let i in detail.cameraInfo) {
      data[detail.cameraInfo[i].id] = detail.cameraInfo[i];
    }
    if (detail.cameraInfo.length > 0) {
      center.value.lat = detail.cameraInfo[0].latitude;
      center.value.lng = detail.cameraInfo[0].longitude;
    }
  }
  camInfo.value = data;
};
const camInfo = ref({});
const locationData = computed(() => state.tracingObject.locationData);
const devices = computed(() => state.deviceManagement.devices);
const showCommonMap = ref(false);
const lastCamera = computed(() => {
  if (locationData.value.length > 0) {
    return locationData.value[0].camera.id;
  }
});
const lastLocation = computed(() => {
  if (locationData.value.length > 0) {
    return {
      lat: locationData.value[0].camera.latitude,
      lng: locationData.value[0].camera.longitude,
    };
  } else {
    return {
      lat: 21.051448,
      lng: 105.8002,
    };
  }
});
const showTrinhSat = ref(false);
onMounted(async () => {
  if (route.query.subTab && route.query.subTab !== 'undefined') {
    activeMap.value = Number(route.query.subTab);
  }
  let paramsDetail = null
  let paramsLocation = null
  if(activeMap.value === MAP_TYPE.COMMON_IO) {
    paramsDetail = {
      dateFrom: dayjs().subtract(30, 'day').startOf('day').toDate(),
      dateTo: dayjs().endOf('day').toDate(),
    }
  } else {
    paramsLocation = {
      dateFrom: dayjs().subtract(30, 'day').startOf('day').toDate(),
      dateTo: dayjs().endOf('day').toDate(),
    }
  }
  let promises = [
    dispatch('tracingObject/getDetail', {
      id: route.query.id,
      params: paramsDetail
    }),
    dispatch('deviceManagement/getAll'),
    dispatch('user/getAll'),
    dispatch('tracingObject/getLocation', { id: route.query.id, params: paramsLocation }),
    dispatch('tracingObject/getListGroupCamera'),
    dispatch('ioGroup/getAllGroup'),
  ];
  await Promise.all(promises);
  getCamera();
  groupCamera.value = state.tracingObject.tracingObjectDetail.groupCamInfo
    ? state.tracingObject.tracingObjectDetail.groupCamInfo
    : [];
  getDataCommonMap();
  showCommonMap.value = true;
  getLocationTrinhSat();
  getDataTravelMap();
  getDataCamMap();
  getDataHeatmap();
  isLoading.value = false;
});

const getLastLocation = () => {
  if (locationData.value.length > 0) {
    lastCamera.value = locationData.value[0].camera.id;
    lastLocation.value.lat = locationData.value[0].camera.latitude;
    lastLocation.value.lng = locationData.value[0].camera.longitude;
  }
};
const router = useRouter();
const changeMap = async (map) => {
  await router.push({
    query: {
      ...route.query,
      subTab: map,
    },
  });
  isLoadingMap.value = true;
  activeMap.value = map;
  let paramsDetail = null
  let paramsLocation = null
  if(activeMap.value === MAP_TYPE.COMMON_IO) {
    paramsDetail = {
      dateFrom: dayjs().subtract(30, 'day').startOf('day').toDate(),
      dateTo: dayjs().endOf('day').toDate(),
    }
  } else {
    paramsLocation = {
      dateFrom: dayjs().subtract(30, 'day').startOf('day').toDate(),
      dateTo: dayjs().endOf('day').toDate(),
    }
  }
  let promises = [
    dispatch('tracingObject/getDetail', { id: route.query.id, params: paramsDetail }),
    dispatch('tracingObject/getLocation', { id: route.query.id, params: paramsLocation }),
  ];
  await Promise.all(promises);
  getCamera();
  if (activeMap.value === MAP_TYPE.TRAVEL_ROUTE) {
    getDataTravelMap();
  } else if (activeMap.val === MAP_TYPE.COMMON_IO) {
    getDataCommonMap();
  } else if (activeMap.val === MAP_TYPE.CAMERA_LOCATION) {
    getDataCamMap();
  } else if (activeMap.val === MAP_TYPE.HEATMAP) {
    getDataHeatmap();
  }
  isLoadingMap.value = false;
};

const getLocationTrinhSat = () => {
  let arr = [];
  dayjs.extend(relativeTime);

  devices.value.map((e) => {
    let data = {
      lat: e.latitude,
      lng: e.longitude,
      type: MARKER_TYPE.TRINH_SAT,
      isUser: true,
      id: e.userId,
      infoWindow: `<div class="content-wrapper p-16px ">
            <div class="relative">
              <img
                src="${
                  e.avatar ? e.avatar : require('@/static/img/no-img.png')
                }"
                width="64"
                height="64"
                class="rounded-full w-[64px] h-[64px] flex-shrink-0 object-cover"
              />
              <img
                src="/assets/ems/online.png"
                width="16"
                height="16"
                class="w-[16px] h-[16px] absolute bottom-0 right-0"
              />
            </div>
            <div class="flex flex-col justify-center gap-[12px]">
              <span class="text-base text-ems-white font-semibold whitespace-nowrap">ĐC. ${
                e.userName ? e.userName : e.userId
              }</span>
              <div class="font-semibold text-ems-gray200 w-[300px]">${
                e.location
              }</div>
              <li class="text-[12px] font-semibold text-ems-gray500">${dayjs(
                e.createdTime
              ).fromNow()}</li>
              <div class="flex gap-[16px] justify-end items-center">
                <div class="h-[36px] w-[36px] hover:bg-ems-gray700 rounded-full bg-ems-gray800 flex-shrink-0 flex justify-center items-center">
                  <img
                    src="/assets/ems/mic.svg"
                    width="14"
                    height="19"
                    class="flex-shrink-0 object-cover"
                  />
                </div>
                <div class="h-[36px] w-[36px] hover:bg-ems-gray700 rounded-full bg-ems-gray800 flex-shrink-0 flex justify-center items-center">
                  <img
                    src="/assets/ems/chat.svg"
                    width="14"
                    height="19"
                    class="flex-shrink-0 object-cover"
                  />
                </div>
              </div>
            </div>
          </div>`,
    };
    arr.push(data);
  });
  markersTrinhSat.value = arr;
};

const getDataCommonMap = (selectedIO, selectedCam) => {
  let arr = [];
  for (let i in camInfo.value) {
    arr.push(getDataMarkerCommon(camInfo.value[i], selectedIO, selectedCam));
  }
  markersCommon.value = arr;
};
const getDataMarkerCommon = (data, selectedIO, selectedCam) => {
  let marker = {
    lat: data.latitude,
    lng: data.longitude,
    type: MARKER_TYPE.DEFAULT,
    cameraId: data.id,
  };
  if (selectedIO && selectedIO.listCamId.includes(data.id)) {
    marker.type = MARKER_TYPE.DO;
  }
  if (selectedCam && selectedCam.includes(data.id)) {
    marker.type = MARKER_TYPE.VANG;
  }
  if (selectedIO && selectedIO.listCamId.includes(data.id)) {
    marker.infoWindow = getInfoWindow(
      selectedIO.name ? selectedIO.name : selectedIO.profileUUID,
      selectedIO.firstTime,
      selectedIO.lastTime,
      data.name,
      data.location,
      selectedIO.imageCropUrl,
      `eventId=${selectedIO.profileUUID}&objectId=${route.query.id}&searchCamera=${data.id}&idTracing=${route.query.id}&tab=${route.query.tab}&subTab=${route.query.subTab}`
    );
  }
  return marker;
};

const getInfoWindow = (
  name,
  firstTime,
  lastTime,
  camera,
  address,
  img,
  query,
  cameraId,
  status
) => {
  let data = `<div class="content-wrapper p-16px">
            <img
              src="${img ? img : require('@/static/img/no-img.png')}"
              width="120"
              height="120"
              class="rounded-lg border-1 border-solid border-ems-gray300 mr-1 w-[120px] h-[120px] flex-shrink-0 object-cover"
            />
            <div class="flex flex-col justify-center gap-[12px]">
              <span class="text-base text-ems-white font-semibold">${name}</span>
              <ul class="list-disc ml-20px flex flex-col gap-1 w-[300px]">
                <li class="text-[12px] leading-normal font-semibold text-ems-gray500"> ${t(
                  'object_tracing.vi-tri'
                )}   ${address} </li>
             `;
  if (activeMap.value === MAP_TYPE.TRAVEL_ROUTE) {
    if (cameraId === lastCamera.value) {
      data =
        data +
        `<li class="text-[12px] leading-normal font-semibold text-ems-gray500"> ${t(
          'object_tracing.time'
        )}:  ${formatDate(firstTime, 'DD/MM/YYYY - HH:mm:ss')}  </li>
        <li class="text-[12px] leading-normal font-semibold text-ems-gray500"> ${t(
          'object_tracing.status'
        )}:  ${
          status === EVENT_STATUS_VALUE.CHECKIN
            ? t('identification-event.checkin')
            : status === EVENT_STATUS_VALUE.CHECKOUT
            ? t('identification-event.checkout')
            : status === EVENT_STATUS_VALUE.APPEAR
            ? t('identification-event.appear')
            : status === EVENT_STATUS_VALUE.INTRUSION
            ? t('identification-event.intrusion')
            : ''
        } </li>
        `;
    } else {
      data =
        data +
        `<li class="text-[12px] leading-normal font-semibold text-ems-gray500"> ${t(
          'object_tracing.time2'
        )}  ${formatDate(firstTime, 'DD/MM/YYYY - HH:mm:ss')}
        </li>`;
    }
  }
  if (activeMap.value === MAP_TYPE.COMMON_IO) {
    data =
      data +
      ` <li class="text-[12px] leading-normal font-semibold text-ems-gray500"> ${t(
        'object_tracing.time3'
      )}   ${formatDate(firstTime, 'DD/MM/YYYY - HH:mm:ss')}   </li>
      `;
  }
  data =
    data +
    `</ul>
    <a href='/trace-management/identification-event?${query}' rel="noreferrer nofollow"
              class="block w-[fit-content] rounded-full text-12px !text-ems-boTro4_600 px-16px py-3px border-1px border-solid border-ems-boTro4_600"
              onclick="event.stopPropagation();"> ${t(
                'object_tracing.view-event'
              )} </a>
            </div>
          </div>`;
  return data;
};
const selectIO = (item) => {
  getDataCommonMap(item);
  commonMap.value.setMarker(markersCommon.value);
  commonMap.value.moveToLocation(
    camInfo.value[item.listCamId[0]].latitude,
    camInfo.value[item.listCamId[0]].longitude
  );
};
const isLoadingList = ref(false);
const onClickMarkerCommon = async (item) => {
  getDataCommonMap(null, [item.cameraId]);
  commonMap.value.setMarker(markersCommon.value);
  commonMap.value.moveToLocation(
    camInfo.value[item.cameraId].latitude,
    camInfo.value[item.cameraId].longitude
  );
  commonIO.value.setCameraId(item.cameraId);
  isLoadingList.value = true;
  await dispatch('tracingObject/getDetail', {
    params: { cameraIds: item.cameraId },
    id: route.query.id,
  });
  isLoadingList.value = false;
};
const onSearch = async (data) => {
  isLoadingList.value = true;
  await dispatch('tracingObject/getDetail', data);
  isLoadingList.value = false;
  let ids = data.params.cameraIds ? data.params.cameraIds.split(',') : [];
  if (ids.length > 0) {
    getDataCommonMap(null, ids);
    commonMap.value.setMarker(markersCommon.value);
    commonMap.value.moveToLocation(
      camInfo.value[ids[0]].latitude,
      camInfo.value[ids[0]].longitude
    );
  }
};

const travelMap = ref();
const markersTravel = ref([]);
const flightPathTravel = ref([]);
const moveLastLocation = () => {
  if (locationData.value.length > 0) {
    travelRoute.value.selectItem(0, locationData.value[0]);
  }
};
const onClickShowTS = () => {
  showTrinhSat.value = !showTrinhSat.value;
  if (showTrinhSat.value) {
    travelMap.value.setMarkerTrinhSat(markersTrinhSat.value);
    if (markersTrinhSat.value.length > 0)
      travelMap.value.moveToLocation(
        markersTrinhSat.value[0].lat,
        markersTrinhSat.value[0].lng
      );
  } else {
    travelMap.value.clearMarkerTrinhSat();
    moveLastLocation();
  }
};
const onClickMarkerTravel = (item) => {
  for (let i in locationData.value) {
    if (item.id === locationData.value[i].camera.id) {
      travelRoute.value.selectItem(Number(i), locationData.value[i]);
      break;
    }
  }
};
const getDataTravelMap = (selectedItem) => {
  let arr = [];
  let path = [];
  for (let i in locationData.value) {
    let item = locationData.value[i];
    let data = {
      lat: item.camera.latitude,
      lng: item.camera.longitude,
      type: MARKER_TYPE.DEFAULT,
      id: item.camera.id,
    };
    if (item.camera.id === lastCamera.value) {
      data.type = MARKER_TYPE.VANG;
    }
    if (selectedItem && selectedItem.camera.id === item.camera.id) {
      data.type = MARKER_TYPE.DO;
      data.infoWindow = getInfoWindow(
        item.camera.name,
        item.firstTime,
        item.lastTime,
        item.camera.name,
        item.camera.location,
        item.imageCropUrl,
        `searchCamera=${item.camera.id}&searchName=${selectedItem.profileUUID}&isTracing=true&idTracing=${route.query.id}&tab=${route.query.tab}&subTab=${route.query.subTab}`,
        item.camera.id,
        item.directionType
      );
      if (item.camera.id === lastCamera.value) {
        data.type = MARKER_TYPE.VANG_SELECTED;
      }
    }

    arr.push(data);
    path.push([item.camera.longitude, item.camera.latitude]);
  }
  flightPathTravel.value = path.reverse();
  markersTravel.value = arr;
};
const selectLocation = (item) => {
  getDataTravelMap(item);
  travelMap.value.setMarker(markersTravel.value);
  travelMap.value.moveToLocation(item.camera.latitude, item.camera.longitude);
};
const onSearchLocation = async (data) => {
  isLoadingList.value = true;
  await dispatch('tracingObject/getLocation', data);
  isLoadingList.value = false;
  getDataTravelMap();
  travelMap.value.setMarker(markersTravel.value);
  travelMap.value.updateLineString(flightPathTravel.value);
};

const cameraMap = ref();
const cameraLocation = ref();
const markersCam = ref([]);
const onClickMarkerCam = async (item) => {
  isLoadingList.value = true;
  cameraLocation.value.setCameraId(item.id);
  await dispatch('tracingObject/getLocation', {
    params: { cameraIds: item.id },
    id: route.query.id,
  });
  isLoadingList.value = false;
  let img = {};
  for (let data of locationData.value) {
    if (item.id === data.camera.id) {
      img[data.camera.id] = data.imageCropUrl;
    }
  }
  getDataCamMap([item.id], img);
  cameraMap.value.setMarker(markersCam.value);
  cameraMap.value.moveToLocation(item.lat, item.lng);
  setTimeout(() => {
    for (let data of locationData.value) {
      if (item.id === data.camera.id) {
        openOnlineCam(data);
        break;
      }
    }
  }, 100);
};
const getDataCamMap = (selectedItem, img) => {
  let arr = [];
  for (let i in camInfo.value) {
    let item = camInfo.value[i];
    let data = {
      lat: item.latitude,
      lng: item.longitude,
      type: MARKER_TYPE.CAMERA_DEFAULT,
      id: item.id,
    };
    if (selectedItem && selectedItem.includes(item.id)) {
      data.type = MARKER_TYPE.CAMERA_SELECTED;
      data.infoWindow = `<div class="content-wrapper p-16px">
            <img
              src="${
                img[item.id] ? img[item.id] : require('@/static/img/no-img.png')
              }"
              width="120"
              height="120"
              class="rounded-lg border-1 border-solid border-ems-gray300 mr-1 w-[120px] h-[120px] flex-shrink-0 object-cover"
            />
            <div class="flex flex-col justify-center gap-[12px]">
              <span class="text-base text-ems-white font-semibold">${
                item.name
              }</span>
              <ul class="list-disc ml-20px flex flex-col gap-1 w-[300px]">
                <li class="text-[12px] leading-normal font-semibold text-ems-gray500"> ${t(
                  'object_tracing.vi-tri'
                )}   ${item.location} </li>
              </ul>
              <span class="block w-[fit-content] rounded-full text-12px !text-ems-boTro4_600 px-16px py-3px border-1px border-solid border-ems-boTro4_600 cursor-pointer " id="view-online-${
                item.id
              }"
              onclick="event.stopPropagation()"> ${t(
                'object_tracing.view-online'
              )} </span>
            </div>
          </div>
        `;
    }
    arr.push(data);
  }
  markersCam.value = arr;
};
const showOnlineCam = ref(false);
const onlineCam = ref();
const selectCamera = (item) => {
  let img = {};
  img[item.camera.id] = item.imageCropUrl;
  getDataCamMap([item.camera.id], img);
  cameraMap.value.setMarker(markersCam.value);
  cameraMap.value.moveToLocation(item.camera.latitude, item.camera.longitude);
  setTimeout(() => {
    openOnlineCam(item);
  }, 100);
};
const openOnlineCam = (item) => {
  let property = document.getElementById(`property-${item.camera.id}`);
  if (property) {
    property.addEventListener('mouseenter', () => {
      let camera = document.getElementById(`view-online-${item.camera.id}`);
      if (camera) {
        camera.addEventListener('click', () => {
          onlineCam.value = item;
          showOnlineCam.value = true;
        });
      }
    });
  }
};
const onSearchCamera = async (data) => {
  isLoadingList.value = true;
  await dispatch('tracingObject/getLocation', data);
  isLoadingList.value = false;
  let ids = data.params.cameraIds ? data.params.cameraIds.split(',') : [];
  if (ids.length > 0) {
    let img = {};
    for (let item of locationData.value) {
      if (ids.includes(item.camera.id)) {
        img[item.camera.id] = item.imageCropUrl;
      }
    }
    getDataCamMap(ids, img);
    cameraMap.value.setMarker(markersCam.value);
    cameraMap.value.moveToLocation(
      camInfo.value[ids[0]].latitude,
      camInfo.value[ids[0]].longitude
    );
  }

  setTimeout(() => {
    for (let item of locationData.value) {
      if (ids.includes(item.camera.id)) {
        openOnlineCam(item);
      }
    }
  }, 100);
};

const markersHeatmap = ref([]);
const heatmap = ref();
const formStateHeatmap = reactive({
  dateFrom: dayjs().subtract(30, 'day'),
  dateTo: dayjs(),
});
const getDataHeatmap = () => {
  let data = [];
  for (let item of locationData.value) {
    data.push({
      lat: item.camera.latitude,
      lng: item.camera.longitude,
      weight: item.count,
      type: MARKER_TYPE.DEFAULT,
    });
  }
  markersHeatmap.value = data;
};
watch(
  () => formStateHeatmap,
  (val) => {
    onSearchHeatmap();
  },
  {
    deep: true,
  }
);

const onSearchHeatmap = async () => {
  isLoadingList.value = true;
  await dispatch('tracingObject/getLocation', {
    params: formStateHeatmap,
    id: route.query.id,
  });
  getDataHeatmap();
  heatmap.value.setData(markersHeatmap.value);
  isLoadingList.value = false;
};
</script>
<style lang="scss">
.tooltip-name-icon .ant-tooltip-content {
  border-radius: 0.5rem;
}
.tooltip-name-icon .ant-tooltip-content .ant-tooltip-inner, .tooltip-name-icon .ant-tooltip-content .ant-tooltip-arrow-content {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 85, 85, var(--tw-bg-opacity));
}
.tooltip-name-icon .ant-tooltip-content .ant-tooltip-inner {
  border-radius: 0.5rem;
  border-style: none;
}
.location-tab .from-to-date .ant-row .ant-col:first-child {
  padding-left: 0 !important;
}
.location-tab .from-to-date .ant-row .ant-col:last-child {
  padding-right: 0 !important;
}
.location-tab .from-to-date .ant-row .ant-picker {
  border-radius: 0 !important;
  height: 34px !important;
  --tw-bg-opacity: 1;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity));
  border-style: none;
}
</style>
